import React from "react";
import FreatureCard2 from "../../components/FreatureCard2";
import imgE from "../../assets/image/inner-page/png/explore.png";

const items = [
  {
    icon: imgE,
    title: "Easy Administration",
    content:
      "Full control over the creation and administration of all your videos",
  },
  {
    icon: imgE,
    title: "Powerful Publishing Tools",
    content:
      "Publishing control which is intuitive and easy to understand. One publish for all devices",
  },
  {
    icon: imgE,
    title: "Robust Content Templates",
    content:
      "Multiple responsive templates to choose from, designed professionally",
  },
  {
    icon: imgE,
    title: "Latest Plugins & Features",
    content:
      "Updates, new plugins and features to be rolled out monthly for your use",
  },
  {
    icon: imgE,
    title: "Exemplary Support",
    content:
      "Detailed documentation and 24 hour support available",
  },
  {
    icon: imgE,
    title: "Detailed Analytics",
    content:
      "Tracking engagement on your platforms is an essential part of carrying out your content strategy",
  },
];

const Features = () => {
  return (
    <div className="pb-9 pb-md-13 pb-lg-15 mt-20">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-7 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83">
                Features of our simple CMS
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-4 col-lg-5 col-md-6 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <FreatureCard2 options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;

import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/cms/Hero";
import CTA from "../../sections/cms/CTA";
import Features from "../../sections/cms/Features";
import Wave from "../../sections/common/Wave";
import SEO from '../../components/SEO';
import metaImg from '../../assets/image/metaimg/default.jpg';

const CmsPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Interactive Video Content Management System"
          description="Create your own Interactive Videos with our simple Content Management System"
          image={metaImg}
        />           
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/cms">CMS</Link>
              </li>
              </ol>
           </nav>
        </div>                
        <Hero />
        <Wave color="#F7F9FC" />
        <CTA className="grey" />
        <Features />
      </PageWrapper>
    </>
  );
};
export default CmsPage;

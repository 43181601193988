import React from "react";
import { Link } from "gatsby";

import imgC from "../../assets/image/inner-page/webp/desktop-dashboard-full.webp";
import { SRLWrapper } from "simple-react-lightbox";

const CTA = ({ className }) => {
  const options = {
    settings: {
      overlayColor: "rgba(233, 247, 254, 1)",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "#258aff",
      iconColor: "#fff",
    },
    caption: {
      captionColor: "#258aff",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "none",
    }
  };
  return (
    <div className={`pt-4 pt-md-6 pt-lg-10 pb-5 pb-md-8 pb-lg-15 mb-lg-1 ${className} `}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
              <SRLWrapper options={options}><img className="w-100 lightbox" src={imgC} alt="Digma Content Management System" /></SRLWrapper>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <h3
                className="font-size-10 mb-2 letter-spacing-n83"
                data-aos="fade-up"
                data-aos-duration={600}
              >
                Would you like a demonstration?
              </h3>
              <p
                className="font-size-6 mb-0 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >
                Please contact us for a full demo with one of our team members.{" "}
              </p>
              <div
                className="mt-11"
                data-aos="fade-up"
                data-aos-duration={1200}
              >
                <Link
                  to="/contact"
                  className="btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3"
                >
                  Let's Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
